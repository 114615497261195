import { BorderColor, Groups, Visibility, VisibilityOff } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchGet, LayoutContext } from "wcz-layout";
import OrderDetails from "../../components/order/OrderDetails";
import { eqOrderUrl, peoplesoftUrl } from "../../utils/BaseUrl";

interface Person {
    employeeId: string,
    position: string
}

export default function ToolingReport() {
    const [detailsOrderId, setDetailsOrderId] = useState<string>("");
    const [includeScrap, setIncludeScrap] = useState<boolean>(false);
    const { changeTitle, user, t } = useContext(LayoutContext);
    const apiRef = useGridApiRef();

    useEffect(() => changeTitle("Tooling Report"), []);

    const { data = [], isLoading, refetch } = useQuery(["toolingReport"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/report/tooling${includeScrap ? "?includeScrap=true" : ""}`, signal), {
        refetchOnWindowFocus: true
    });

    useEffect(() => {
        refetch();
    }, [includeScrap]);

    const { data: employees = [] } = useQuery<Person[], Response>(["people"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=companyCode==530;status==Active&fields=position,employeeId`, signal));

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'actions', type: 'actions', width: 50,
            getActions: (params: any) => {
                const userPosition = employees.find(employee => employee.employeeId === user.id)?.position;

                if (params.row.orderId && user.department === params.row.keeperDepartment && userPosition?.includes("Leader"))
                    return [
                        <GridActionsCellItem icon={<BorderColor />} label="Details" onClick={() => setDetailsOrderId(params.row.orderId)} />,
                    ];

                return [];
            }
        },
        { field: 'partNumber', headerName: "PN", width: 250 },
        { field: 'description', headerName: t("Description"), width: 350 },
        { field: 'serialNumber', headerName: "SN", width: 220, },
        { field: 'location', headerName: t("Location"), width: 180 },
        { field: 'owner', headerName: t("Owner"), width: 180 },
        { field: 'keeper', headerName: t("Keeper"), width: 220, },
        { field: 'keeperDepartment', headerName: t("Department"), width: 200 },
        { field: 'project', headerName: t("Project"), width: 170 },
        { field: 'remark', headerName: t("Remark"), width: 350 }
    ] as GridColDef[], [t]);

    const filterMyDepartment = useCallback(() => {
        apiRef.current.setFilterModel({
            items: [{
                field: "keeperDepartment",
                operator: "equals",
                value: user.department
            }]
        });
    }, [apiRef, user.department]);

    const handleIncludeScrapChange = useCallback(() => setIncludeScrap(!includeScrap), [includeScrap]);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} apiRef={apiRef}
                    slotProps={{
                        toolbar: {
                            export: true,
                            viewKey: "toolingReport",
                            items: [
                                { title: t("MyDepartment"), onClick: filterMyDepartment, icon: <Groups /> },
                                { title: "SCRAP", onClick: handleIncludeScrapChange, icon: includeScrap ? <Visibility /> : <VisibilityOff /> }
                            ]
                        } as GridToolbarProps
                    }} />
            </TableContainer>

            <OrderDetails orderId={detailsOrderId} setOrderId={setDetailsOrderId} />
        </Fragment>
    );
}